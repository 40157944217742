








































































































import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment/moment";
import { processError } from "@/utils/ComponentUtils";
import Application from "@/state/Application";
import PortalSelect from "@/components/common/PortalSelect.vue";
import { DayPilot, DayPilotScheduler } from "daypilot-pro-vue";
import EmploymentService from "@/services/EmploymentService";
import { IEmployment } from "@/dto/payroll/IEmployment";
import { EmploymentFilter } from "@/dto/payroll/Filters";
import { CounterpartyType } from "@/constants/CounterpartyType";
import { EmploymentStatus } from "@/components/payroll/business/employments/contractors/Contractor";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import TimesheetService from "@/services/payroll/TimesheetService";
import {
  CreateTimeSheetRecordPayload,
  TimeSheetFilter, TimeSheetPayDay,
  TimeSheetPayPeriod,
  TimeSheetRecord,
  TimesheetType, UpdateTimeSheetRecordPayload
} from "@/dto/payroll/timesheet/TimesheetPayloads";
import { v4 as uuidv4 } from "uuid";
import { Moment } from "moment-timezone";
import TimeSheetDetails from "@/components/payroll/timesheet/TimeSheetDetails.vue";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import TimeSheetPeriod from "@/components/payroll/timesheet/TimeSheetPeriod.vue";
import { EventBus } from "@/utils/EventBus";
import { namespace } from "vuex-class";

export type Mode = "INDIVIDUAL" | "BUSINESS";
const AppModule = namespace("App");

@Component<BusinessCalendarTimesheet>({
  components: {
    PortalSelect,
    DayPilotScheduler,
    PaginationComponent,
    TimeSheetDetails,
    BTPortalCollapse,
    TimeSheetPeriod
  },
  methods: {
    uuidv4() {
      return uuidv4();
    },
    moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
      return moment(inp, format, language, strict)
    }
  },
  watch: {
    isMobile(n) {
      this.checkIsMobile(n);
    },
    timeFormat() {
      if (this.periodMode === 1) {
        this.config.timeHeaders = this.timeHeadersForHours;
      }
    },
    periodMode(n) {
      if (n === 0) {
        this.config.timeHeaders = [{groupBy: "Day", format: "dddd dd.MM", height: 68}];
        this.config.cellWidth = 221;
        this.config.eventMinWidth = 221;
        this.config.scale = "Day";
        this.config.snapToGrid = true;
        this.config.useEventBoxes = "Always";
        this.config.startDate = (this.config.startDate as DayPilot.Date).firstDayOfWeek();
        this.config.days = 7;
        this.config.cornerText = "Days";
      } else {
        this.config.timeHeaders = this.timeHeadersForHours;
        this.config.cellWidth = 64;
        this.config.eventMinWidth = 0;
        this.config.scale = "Hour";
        this.config.snapToGrid = false;
        this.config.useEventBoxes = "Never";
        if (DayPilot.Date.today() >= (this.config.startDate as DayPilot.Date).firstDayOfWeek() && DayPilot.Date.today() <= (this.config.startDate as DayPilot.Date).firstDayOfWeek().addDays(6)) {
          this.config.startDate = DayPilot.Date.today();
        } else {
          this.config.startDate = (this.config.startDate as DayPilot.Date);
        }
        this.config.days = 1;
        this.config.cornerText = 'Hours';
      }
      this.selectedWeek = `${moment(this.startDateString).startOf('week').format('MMM DD YYYY')}`;
      this.selectedDay = moment(this.startDateString).format('MMM DD YYYY');
      this.updateCalendar();
    }
  }
})
export default class BusinessCalendarTimesheet extends Vue {

  @AppModule.State
  private isMobile!: boolean;

  $refs!: {
    calendarRef: DayPilotScheduler,
  }

	@Prop()
	private timeFormat!: string

	@Prop({default: "BUSINESS"})
	private mode!: Mode

  @Prop({ default: null, required: false })
  private project!: number | null

  @Prop({ default: function () {return [];}, required: false })
  employmentsOfProject!: IEmployment[]

	@Prop({default: function () {return [];}, required: false})
	private employments!: IEmployment[]

  private totalPages = 1;
  private periodModes = [{ title: 'Week', value: 0 }, { title: 'Day', value: 1 }];
  private periodMode = 0;
  private selectedWeek = `${moment(new Date()).startOf('week').format('MMM DD YYYY')}`;
  private selectedDay = moment(new Date()).format('MMM DD YYYY');
  private internalEmployment: Array<IEmployment> = [];
  private employmentsFilter = new EmploymentFilter({
    types: ["STAFF", "FOREIGN"],
    contractorType: CounterpartyType.PERSON,
    status: [EmploymentStatus.ACTIVE, EmploymentStatus.NEW, EmploymentStatus.PENDING_FOR_DOCUMENTS],
    employerId: this.id,
  });
  private employmentIdArr: Array<number> = [];
  private startYear = 2024;
  private endYear = this.startYear + 12;
  private firstDayStartYear = new DayPilot.Date(`${this.startYear}-01-01T00:00:00`);
  private firstDayEndYear = new DayPilot.Date(`${this.endYear + 12}-01-01T00:00:00`);
  private timesheetPeriods: Array<TimeSheetPayPeriod> = [];
  private records: Array<TimeSheetRecord> = [];
  private timerTriggerFromCalendar = 0;
  // eslint-disable-next-line no-undef
  private idIntervalTimer: NodeJS.Timeout | null = null;
  // private timesheetPeriodsForAllEmployee: Array<TimeSheetPayPeriod> = [];
  // private employmentIdArrForAllEmployee: Array<number> = [];
  private keyForCalendar = 0;

  private config: DayPilot.SchedulerConfig = {
    timeHeaders: [{groupBy: "Day", format: "dddd dd.MM", height: 80}],
    scale: "Day",
    days: 7,
    startDate: DayPilot.Date.today().firstDayOfWeek(),
    resources: [],
    events: [],
    cellWidth: 221,
    durationBarVisible: false,
    cornerText: 'Days',
    rowHeaderColumnHeaderHeight: 80,
    rowHeaderWidthAutoFit: true,
    snapToGrid: true,
    useEventBoxes: "Always",
    eventHeight: 80,
    eventMinWidth: 221,
    eventPadding: '20px 15px',
    eventMoveHandling: 'Disabled',
    eventResizeHandling: 'Disabled',
    eventClickHandling: 'Disabled',
    // eventHoverHandling: 'Disabled',
    eventRightClickHandling: 'Disabled',
    dynamicLoading: true,
    onScroll: async (args) => {
      args.events = this.loadEvents();
      args.loaded();
      await this.$nextTick();
      this.attachEventHandlerToTimerMenuButtons();
    },
    bubble: new DayPilot.Bubble({
      hideOnClick: false,
      hideOnHover: false,
      showAfter: 0,
      hideAfter: 0.1,
      onDomAdd: (args) => {
        if (args.source.data.payDate.records.length || (!args.source.data.payDate.records.length && args.source.data.payDate.date.toString() === this.today)) {
          args.element = '';
          return;
        }
        const payDate = args.source.data.payDate;
        const component = new TimeSheetDetails({
          propsData: {
            timeFormat: this.timeFormat,
            employment: args.source.data.employment,
            day: payDate,
            project: this.project,
            totalForWeek: payDate.duration,
            timerTriggerFromCalendar: payDate.isClosed ? null : this.timerTriggerFromCalendar,
            calendarMode: true,
            onUpdateRecord: this.updateCalendar,
            onCreateRecord: this.updateCalendar,
            onDeleteRecord: this.updateCalendar,
          },
        });
        component.$on('onUpdateRecord', this.updateCalendar)
        component.$on('onCreateRecord', this.updateCalendar)
        component.$on('onDeleteRecord', this.updateCalendar)
        args.element = component.$mount().$el;
      }
    })
  };

  async mounted(): Promise<void> {
    this.checkIsMobile(this.isMobile);
    this.idIntervalTimer = setInterval(
      () => {
        this.checkPlug();
        this.timerTriggerFromCalendar += 1;
        this.renderDisplayTimer();
      },
      1000)
	  if (this.employments.length == 0) {
		  await this.loadEmployments();
	  } else {
		  this.internalEmployment = this.employments
		  this.employmentIdArr = this.internalEmployment.map(employment => employment.id) as number[];
		  this.timesheetFilter.employmentsId = this.employmentIdArr
	  }
    await this.updateCalendar();
    this.$refs.calendarRef.control.scrollTo(DayPilot.Date.today());
    // await this.loadAllEmployee();
    document.addEventListener('click', this.globalOutsidePopupClick);
    EventBus.$on('onDeleteRecord', this.updateCalendar);
  }

  beforeDestroy(): void {
    if (this.idIntervalTimer) {
      clearInterval(this.idIntervalTimer);
    }
    document.removeEventListener('click', this.globalOutsidePopupClick);
    EventBus.$off('onDeleteRecord', this.updateCalendar);
  }

  checkIsMobile(n: boolean): void {
    this.config.eventClickHandling = n ? 'Bubble' : 'Disabled';
    this.config.eventHoverHandling = n ? 'Disabled' : 'Bubble';
  }

  checkPlug(): void {
    if (this.$refs.calendarRef.$el.innerHTML === 'You are using a trial version of DayPilot Pro.') {
      this.keyForCalendar += 1;
    }
  }

  globalOutsidePopupClick(e: MouseEvent): void {
    const element = e.target;
    const popover = document.querySelector('.calendar-timesheet__popover');

    if (!(element as HTMLElement).classList.contains('timesheet-popover__button') && (element instanceof Node && !popover?.contains(element as HTMLElement))) {
      this.$root.$emit('bv::hide::popover');
    }
  }

  private renderDisplayTimer() {
    const timerDisplays = document.querySelectorAll('.calendar-timesheet__timer-display');
    timerDisplays.forEach(timerDisplay => {
      const event = this.config.events?.find(item => item.id === (timerDisplay as HTMLButtonElement)?.dataset.event);
      if (event) {
        timerDisplay.innerHTML = this.timer(event.payDate);
      }
    })
  }

  private async updateCalendar() {
    // await this.loadEmployments();
    await this.loadPeriod();
    this.loadResources();
  }

  private async loadEmployments() {
    if (!this.project) {
      try {
        Application.startLoading();
        const res = await EmploymentService.getPageByFilter<IEmployment>(this.employmentsFilter);
        this.internalEmployment = res.data.data;
        this.employmentIdArr = res.data.data.map(item => item.id!);
        this.totalPages = res.data.countOfPages;
      } catch (err) {
        processError(err, this);
      } finally {
        Application.stopLoading();
      }
    } else {
      this.internalEmployment = this.employmentsOfProject;
      this.employmentIdArr = this.employmentsOfProject.map(item => item.id!);
    }
  }

  private loadPeriod() {
    Application.startLoading();
    return TimesheetService.getPayPeriod(this.timesheetFilter).then(
      res => {
        this.timesheetPeriods = res.data;
        Application.stopLoading();
      },
      err => processError(err, this)
    )
  }

  private loadResources() {
	  const employerNames: string[] = []
    const listEmployments: Array<DayPilot.ResourceData> = this.timesheetPeriods.map(item => {
		employerNames.push(item.employment.employer.name)
      return {
		  id: item.employment.id!,
	      name: item.employment.details.name!,
	      html: '',
	      duration: '' };
    });
    this.timesheetPeriods.forEach(item => {
      const { employment, duration, payDates, openRecord } = item;
      const currentEmploymentIndex = listEmployments.findIndex(_item => _item.id === employment.id);
	  if (currentEmploymentIndex == -1) return
      listEmployments[currentEmploymentIndex].duration = duration;
      listEmployments[currentEmploymentIndex].html = `<div class="resource-content__container">
                                                        <div>
                                                          <div class="resource-content__name">
																${ this.mode == 'INDIVIDUAL' ? employerNames[currentEmploymentIndex] + '<br>' : ''}
																<strong>${listEmployments[currentEmploymentIndex].name} </strong></div>
                                                          <div class="resource-content__duration">${ listEmployments[currentEmploymentIndex].duration}</div>
                                                        </div>
                                                        ${ payDates.length === 1 && this.isRegular(payDates[0]) && payDates[0].date.toString() === this.today ?
                                                              `<button data-event="${listEmployments[currentEmploymentIndex].id}" class="portal-button link calendar-timesheet__timer-button">
                                                                ${ openRecord ? `<svg width="27" height="27" fill="#E14C44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                                  <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm192-96l128 0c17.7 0 32 14.3 32 32l0 128c0 17.7-14.3 32-32 32l-128 0c-17.7 0-32-14.3-32-32l0-128c0-17.7 14.3-32 32-32z"/>
                                                                                </svg>`
                                                                            : `<svg width="20" height="27" fill="#436356" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                                                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/>
                                                                               </svg>`
                                                                }
                                                              </button>` : ''
                                                        }
                                                      </div>`;
      if (payDates.length === 1 && this.isRegular(payDates[0]) && payDates[0].date.toString() === this.today) {
        listEmployments[currentEmploymentIndex].payDate = payDates[0]
      }
    })
    this.config.resources = listEmployments;
  }

  private loadEvents() {
    this.config.events = this.timesheetPeriods.map(item => {
      const { employment, payDates, openRecord } = item;
      return payDates.map(payDate => {
        const isOvertime = !!payDate.records.find(record => record.type === 'OVERTIME');
        if (this.periodMode === 0) {
          const eventId = uuidv4();
          if (payDate.date.toString() === this.today && (isOvertime || this.isRegular(payDate))) {
            return {
              id: eventId,
              resource: employment.id!,
              payDate,
              employment,
              start: new DayPilot.Date(payDate.date),
              end: new DayPilot.Date(payDate.date),
              text: '',
              html: `<div class="event-content__timer">
                      ${ isOvertime ? `<div class="event-content__overtime">Overtime</div>` : ''}
                    <button data-event="${eventId}" class="portal-button link calendar-timesheet__timer-button">
                      ${ openRecord ? `<svg width="27" height="27" fill="#E14C44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                              <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm192-96l128 0c17.7 0 32 14.3 32 32l0 128c0 17.7-14.3 32-32 32l-128 0c-17.7 0-32-14.3-32-32l0-128c0-17.7 14.3-32 32-32z"/>
                                            </svg>`
                      : `<svg width="20" height="27" fill="#436356" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                    <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/>
                                                  </svg>`
                      }
                    </button>
                    <div data-event="${eventId}" class="calendar-timesheet__timer-display">${this.timer(payDate)}</div>
                    <button id="${eventId}" data-event="${eventId}" class="portal-button link calendar-timesheet__menu-button">
                      <svg width="5" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                        <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/>
                      </svg>
                    </button>
                  </div>`,
              fontColor: '#000000',
              backColor: 'transparent',
              borderRadius: '0px',
              padding: '20px 15px',
            }
          }
          return {
            id: eventId,
            resource: employment.id!,
            payDate,
            employment,
            start: new DayPilot.Date(payDate.date),
            end: new DayPilot.Date(payDate.date),
            text: '',
            html: this.getEventHTML(payDate, eventId, isOvertime),
            fontColor: this.getFontColor(payDate.records[payDate.records.length - 1]?.type),
            backColor: this.getBackColor(payDate.records[payDate.records.length - 1]?.type),
            borderRadius: '0px',
            padding: '20px 15px',
          }
        } else {
          return payDate.records.map(record => {
            const eventId = uuidv4();
            return {
              id: eventId,
              resource: employment.id!,
              payDate,
              employment,
              start: new DayPilot.Date(record.workPeriodStart),
              end: new DayPilot.Date(record.workPeriodEnd ? record.workPeriodEnd : record.workPeriodStart),
              text: '',
              html: this.getEventHTML(payDate, eventId, false, record),
              fontColor: this.getFontColor(record?.type),
              backColor: record.workPeriodEnd ? this.getBackColor(record?.type) : '#436356',
              borderRadius: '0px',
              padding: '20px 15px',
            }
          })
        }
      })
    }).flat(2)
    return this.config.events;
  }

  private attachEventHandlerToTimerMenuButtons(): void {
    const timerButtons = document.querySelectorAll('.calendar-timesheet__timer-button');
    const menuButtons = document.querySelectorAll('.calendar-timesheet__menu-button');

    for (let timerButton of timerButtons) {
      timerButton.addEventListener('click', async (e) => {
        const button = e.currentTarget;
        let target;

        if (this.periodMode === 0) {
          target = this.config.events?.find(item => item.id === (button as HTMLButtonElement)?.dataset.event);
        } else {
          target = this.config.resources?.find(item => item.id === Number((button as HTMLButtonElement)?.dataset.event));
        }

        if (target) {
          if (target.payDate.isClosed) {
            await this.autoCreateRecord(target);
          } else {
            await this.stopRecord(target);
          }
          await this.updateCalendar();
        }
      });
    }

    menuButtons.forEach(item => {
      item.addEventListener('click', () => {
        this.$root.$emit('bv::hide::popover');
      })
    })
  }

  private handleChangeRadioGroup() {
    if (this.periodMode === 0) {
      this.config.startDate = new DayPilot.Date(moment(this.selectedWeek).format('YYYY-MM-DD'));
    } else {
      this.config.startDate = new DayPilot.Date(moment(this.selectedDay).format('YYYY-MM-DD'));
    }
    this.updateCalendar();
  }

  private previousPeriod() {
    if (this.periodMode === 0) {
      this.config.startDate = (this.config.startDate as DayPilot.Date).addDays(-7);
    } else {
      this.config.startDate = (this.config.startDate as DayPilot.Date).addDays(-1);
    }
    this.selectedWeek = `${moment(this.startDateString).startOf('week').format('MMM DD YYYY')}`;
    this.selectedDay = moment(this.startDateString).format('MMM DD YYYY');
    this.updateCalendar();
  };

  private nextPeriod() {
    if (this.periodMode === 0) {
      this.config.startDate = (this.config.startDate as DayPilot.Date).addDays(7);
    } else {
      this.config.startDate = (this.config.startDate as DayPilot.Date).addDays(1);
    }
    this.selectedWeek = `${moment(this.startDateString).startOf('week').format('MMM DD YYYY')}`;
    this.selectedDay = moment(this.startDateString).format('MMM DD YYYY');
    this.updateCalendar();
  };

  private changePage(page: number) {
    this.employmentsFilter.pageNumber = page - 1;
    this.updateCalendar();
  }

  private isDayPilotEventData(target: DayPilot.EventData | DayPilot.ResourceData): target is DayPilot.EventData {
    return 'resource' in target;
  }

  private getEventHTML(payDate: TimeSheetPayDay, eventId: string, isOvertime: boolean, record: TimeSheetRecord | null = null): string {
    let lastRecord;

    if (!record) {
      lastRecord = payDate.records[payDate.records.length - 1];
    } else {
      lastRecord = record;
    }

    if (!lastRecord) {
      return '';
    } else if ((this.periodMode === 1 && lastRecord.workPeriodEnd) || (!this.isRegular(payDate) && !isOvertime)) {
      return `<div class="event-content__timesheet">
                ${lastRecord.type.replace(/_/g, ' ')}
              </div>`
    } else if (this.periodMode === 1 && !lastRecord.workPeriodEnd) {
      return `<div class="event-content__timesheet"></div>`
    } else if (this.periodMode === 0 && !record) {
      return `<div class="event-content__timer">
                ${ isOvertime ? `<div class="event-content__overtime">Overtime</div>` : ''}
                <div data-event="${eventId}" class="calendar-timesheet__timer-display">${this.timer(payDate)}</div>
                <button id="${eventId}" data-event="${eventId}" class="portal-button link calendar-timesheet__menu-button">
                  <svg width="5" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                    <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/>
                  </svg>
                </button>
              </div>`
    } else {
      return '';
    }

  }

  getFontColor(type: TimesheetType): string {
    if (this.periodMode === 0 && (type === 'REGULAR' || type === 'OVERTIME')) {
      return '#000000';
    }
    return '#FFFFFF';
  }

  getBackColor(type: TimesheetType): string {
    if (type === 'VACATION' || type === 'LWOP' || (this.periodMode === 1 && type === 'REGULAR')) {
      return '#436356';
    } else if (type === 'SICK_LEAVE' || type === 'USL') {
      return '#D1D1D1';
    } else if (type === 'PENDING_SICK_LEAVE' || type === 'PROCESSING_SICK_LEAVE') {
      return '#E14C44';
    } else {
      return 'transparent';
    }
  }

  private autoCreateRecord(target: DayPilot.EventData | DayPilot.ResourceData): Promise<void> {
    Application.startLoading();
    return TimesheetService.createRecord(
      CreateTimeSheetRecordPayload.builder()
        .workPeriodStart(new Date())
        .date(moment(new Date()).format("YYYY-MM-DD") as unknown as Date)
        .employmentId(this.isDayPilotEventData(target) ? target.resource as number : target.id as number)
        .projectId(this.project)
        .mode("AUTO")
        .build()
    ).then(
      () => {
        Application.stopLoading();
      },
      err => processError(err, this)
    )
  }

  private stopRecord(target: DayPilot.EventData | DayPilot.ResourceData): Promise<void> {
    Application.startLoading()
    const recordForClose = (target.payDate.records as Array<TimeSheetRecord>).find(item => item.workPeriodEnd === null)
    return TimesheetService.updateRecord(
      UpdateTimeSheetRecordPayload.builder()
        .id(recordForClose!.id)
        .employmentId(this.isDayPilotEventData(target) ? target.resource as number : target.id as number)
        .workPeriodEnd(new Date())
        .mode("AUTO")
        .workPeriodStart(recordForClose!.workPeriodStart)
        .build()
    ).then(
      () => {
        Application.stopLoading()
      },
      err => processError(err, this)
    )
  }

  private timer(payDate: TimeSheetPayDay): string {
    if (!payDate.isClosed && moment(payDate.date).dayOfYear() == moment().dayOfYear()) {
      let currentWorkDuration = payDate.durationInSeconds + 1;
      const lastRecord = payDate.records[payDate.records.length - 1];
      currentWorkDuration += Math.floor((new Date().getTime() - moment.tz(lastRecord.workPeriodStart, lastRecord.zoneId).toDate().getTime()) / 1000);
      return `${Math.floor(currentWorkDuration / 3600)}h ${Math.floor(currentWorkDuration / 60 % 60)}m ${currentWorkDuration % 60}s`
    } else {
      return payDate.duration
    }
  }

  private isRegular(payDate: TimeSheetPayDay): boolean {
    return !payDate.isVacation && !payDate.isSickLeave && !payDate.isLWOP && !payDate.isUSL && !payDate.isPendingSickLeave && !payDate.isProcessingSickLeave;
  }

  // private loadAllEmployee() {
  //   Application.startLoading();
  //   this.loadAllEmploymentIds().then(
  //     () => {
  //       if (this.employmentIdArrForAllEmployee.length > 0) {
  //         this.loadPeriodsForAllEmployee()
  //       }
  //     },
  //     err => processError(err, this)
  //   ).then(Application.stopLoading)
  // }
  //
  // private async loadAllEmploymentIds() {
  //   return EmploymentService.getAllByFilter(this.employmentsFilter).then(
  //     res => {
  //       this.employmentIdArrForAllEmployee = res.data.map(item => item.id!)
  //     },
  //     err => processError(err, this)
  //   )
  // }

  // private async loadPeriodsForAllEmployee() {
  //   const timesheetFilter = new TimeSheetFilter(
  //     {
  //       startDate: moment().set({weekday: 0}).format("YYYY-MM-DD"),
  //       endDate: moment().set({weekday: 6}).format("YYYY-MM-DD"),
  //       employmentsId: this.employmentIdArr,
  //       projectId: null,
  //     }
  //   )
  //   TimesheetService.getPayPeriod(timesheetFilter).then(
  //     res => {
  //       this.timesheetPeriodsForAllEmployee = res.data
  //     },
  //     err => processError(err, this)
  //   )
  // }

  scrollToActiveDateRadio(): void {
    const activeRadio = document.querySelector('.date-dropdown__radio-group .active');
    activeRadio?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  get today(): string {
    return moment(new Date()).format('YYYY-MM-DD');
  }

  get timesheetFilter(): TimeSheetFilter {
    return new TimeSheetFilter(
      {
        startDate: moment(this.startDateString).format('YYYY-MM-DD'),
        endDate: this.endDateFilter,
        employmentsId: this.employmentIdArr,
        projectId: this.project,
	      employerId: this.$wss.getCurrent.id
      }
    )
  }

  get checkPreviousTime(): boolean {
    return this.config.startDate === this.firstDayStartYear;
  }

  get checkNextTime(): boolean {
    return this.config.startDate === this.firstDayEndYear;
  }

  get getYears(): Array<number> {
    const years = [];
    for (let year = this.startYear; year <= this.endYear; year++) {
      years.push(year);
    }
    return years;
  }

  get getWeeks(): { text: string, value: string }[] {
    return this.getYears.reduce<{ text: string, value: string }[]>((accumulator, currentValue) => {
      let count = 1;
      const totalWeeksInYear = moment(currentValue).weeksInYear();
      while (count <= totalWeeksInYear) {
        accumulator.push({
          text: `${moment(`${currentValue}-01-01`).week(count).startOf('week').format('MMM DD YYYY')} - ${moment(`${currentValue}-01-01`).week(count).endOf('week').format('MMM DD YYYY')}`,
          value: `${moment(`${currentValue}-01-01`).week(count).startOf('week').format('MMM DD YYYY')}`
        });
        count += 1;
      }
      return accumulator;
    }, []);
  }

  get getDays(): { text: string, value: string }[] {
    return this.getYears.reduce<{ text: string, value: string }[]>((accumulator, currentValue) => {
      let count = 1;
      const totalDaysInYear = new DayPilot.Date(`${currentValue}-01-01`).daysInYear();
      while (count <= totalDaysInYear) {
        accumulator.push({
          text: moment(`${currentValue}-01-01`).dayOfYear(count).format('MMM DD YYYY'),
          value: moment(`${currentValue}-01-01`).dayOfYear(count).format('MMM DD YYYY')
        });
        count += 1;
      }
      return accumulator;
    }, []);
  }

  get stringSelectedDate(): string | undefined {
    if (this.periodMode === 0) {
      return this.getWeeks.find(item => item.value === this.selectedWeek)?.text;
    } else {
      return this.getDays.find(item => item.value === this.selectedDay)?.text;
    }
  }

  get startDateString(): string | undefined {
    return this.config.startDate?.toString();
  }

  get endDateFilter(): string | undefined {
    if (this.periodMode === 0) {
      return moment(this.startDateString).endOf('week').format('YYYY-MM-DD');
    }
    return moment(this.startDateString).format('YYYY-MM-DD');
  }

  get id(): number {
    return this.$wss.getCurrent.id;
  }

  get timeHeadersForHours(): DayPilot.TimeHeaderData[] {
     if (this.timeFormat === 'hh:mm A') {
      return [{groupBy: "Hour", height: 80}];
    } else {
      return [{groupBy: "Hour", format: this.timeFormat, height: 80}];
    }
  }
}
