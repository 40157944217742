


























import {Component, Vue} from "vue-property-decorator";
import BusinessCalendarTimesheet from "@/components/payroll/timesheet/BusinessCalendarTimesheet.vue";
import IndividualTimesheet from "@/components/payroll/timesheet/IndividualTimesheet.vue";
import Workspaces from "@/state/Workspaces";
import {WorkspaceType} from "@/dto/auth/Workspace";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import SelectOption from "@/components/common/SelectOption";
import Application from "@/state/Application";
import {TimeSheetFilter} from "@/dto/payroll/timesheet/TimesheetPayloads";
import {processError} from "@/utils/ComponentUtils";
import CompanyService from "@/services/CompanyService";
import {IEmployment} from "@/dto/payroll/IEmployment";
import {ProjectDTO} from "@/dto/payroll/ProjectDTOs";
import TimesheetReportCreationModal from "@/components/payroll/timesheet/TimesheetReportCreationModal.vue";

@Component<TimeSheet>({
	components: {PortalRadio},
  watch: {
    selectedProject() {
      if (this.$wss.getCurrent.type === WorkspaceType.COMPANY) {
        const currentProject = this.projectsOfCompany.find(item => item.id === this.selectedProject);
        if (currentProject) {
          this.employmentsOfProject = [...currentProject.employments, ...currentProject.managers];
        }
      }
    }
  }
})
export default class TimeSheet extends Vue {

	private format = "hh:mm A"

	private projects: Array<SelectOption> = [];
	private selectedProject: number | null = null;
  private employmentsOfProject: IEmployment[] = [];
  private projectsOfCompany: ProjectDTO[] = [];

	private readonly businessCalendarTimeSheet = BusinessCalendarTimesheet
	private readonly individualTimesheet = IndividualTimesheet

	private timesheet!: typeof Vue

	public created(): void {
		switch (Workspaces.getCurrent.type) {
			case WorkspaceType.PERSON:
			case WorkspaceType.SUBLIME_EMPLOYEE:
				this.timesheet = this.individualTimesheet; break;
			case WorkspaceType.COMPANY:
				this.timesheet = this.businessCalendarTimeSheet
		}
	}

  mounted(): void {
    if (this.$wss.getCurrent.type === WorkspaceType.COMPANY) {
      this.getProjectsForCompany();
    }
  }

  private getProjectsForCompany(): void {
    Application.startLoading();
    CompanyService.getProjectsByCompanyId().then(
      (resp) => {
        this.projectsOfCompany = resp.data;
        this.projects = [SelectOption.builder().title("All").value(null).build(), ...resp.data.map(item => SelectOption.builder().title(item.title).value(item.id).build())];
        this.employmentsOfProject = resp.data.map(item => [...item.employments, ...item.managers]).flat();
        Application.stopLoading();
      },
      err => processError(err, this)
    )
  }

	timeFormats(): SelectOption[] {
		return [
			SelectOption.builder().title("12 hours").value("hh:mm A").build(),
			SelectOption.builder().title("24 hours").value("HH:mm").build()
		]
	}

	private processProjects(projects: Array<SelectOption>) {
		if (projects.length == 2) {
			this.selectedProject = projects[1].value
		}
		this.projects = projects
	}

	private isAvailableReportGeneration(): boolean {
		return this.$wss.isUserSublimeEmployee && this.$wss.getCurrent.type == WorkspaceType.COMPANY
	}

	private showReportModal() {
		this.$modal.show(
			TimesheetReportCreationModal,
			{
				filter: new TimeSheetFilter(
					{
						employerId: this.$wss.getCurrent.id,
					}
				)
			}
		)
	}
}

