





















import {Component, Prop, Vue} from "vue-property-decorator";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import Workspaces from "@/state/Workspaces";
import {
	TimeSheetFilter,
	TimeSheetPayPeriod,
} from "@/dto/payroll/timesheet/TimesheetPayloads";
import {processError} from "@/utils/ComponentUtils";
import Application from "@/state/Application";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import {namespace} from "vuex-class";
import {CounterpartyType} from "@/constants/CounterpartyType";
import moment from "moment-timezone";
import {v4 as uuidv4} from "uuid";
import TimeSheetPeriod from "@/components/payroll/timesheet/TimeSheetPeriod.vue";
import TimesheetService from "@/services/payroll/TimesheetService";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import SelectOption from "@/components/common/SelectOption";
import {PolicySignature} from "@/dto/payroll/policies/PTOPolicyDTO";
import PolicyService from "@/services/payroll/PolicyService";
import TimesheetRequest from "@/components/payroll/timesheet/TimesheetRequest.vue";
import BusinessCalendarTimesheet from "@/components/payroll/timesheet/BusinessCalendarTimesheet.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";

const AppModule = namespace("App");

export interface ExistPolicies {
	pto: boolean,
	slp: boolean
}

@Component(
	{
		computed: {
			SelectOption() {
				return SelectOption
			}
		},
		components: {
			BusinessCalendarTimesheet,
			TimesheetRequest,
			PortalRadio,
			BTPortalCollapse,
			TimeSheetPeriod
		}
	}
)
export default class IndividualTimesheet extends Vue{

	private id = uuidv4()

	@Prop()
	private timeFormat!: string
	@Prop({default: [], required: false})
	private employmentsOfProject!: IEmployment[]

	@Prop({default: null})
	private project!: number | null

	private employments: Array<IEmployment> = [];

	private timesheetPeriods: Array<TimeSheetPayPeriod> = [];

	private employmentFilter: EmploymentFilter = new EmploymentFilter(
		{
			types: ["STAFF", "FOREIGN"],
			contractorType: CounterpartyType.PERSON,
			detailsId: Workspaces.getCurrent.id,
			status: [EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS, EmploymentStatus.NEW]
		}
	)

	get projects(): string {
		this.$emit("projects", [SelectOption.builder().title("All").value(null).build(), ...this.timesheetPeriods.map(it => it.employment).filter(it => it.details.id == Workspaces.getCurrent.id).map(it => it.projects).flat().map(it => SelectOption.builder().title(it.title).value(it.id).build())])
		return ""
	}

	public created(): void {
		Application.startLoading();
		this.loadEmploymentIds().then(
			() => {
				if (this.employments.length > 0) {
					this.loadTimesheetRecords()
				}
			},
			err => processError(err, this)
		)
			.then(Application.stopLoading)
	}

	private async loadEmploymentIds() {
		return EmploymentService.getAllByFilter(this.employmentFilter).then(
			res => {
				this.employments = res.data
			},
			err => processError(err, this)
		)
	}

	private async loadTimesheetRecords() {
		const timesheetFilter = new TimeSheetFilter(
			{
				startDate: moment().set({weekday: 0}).format("YYYY-MM-DD"),
				endDate: moment().set({weekday: 6}).format("YYYY-MM-DD"),
				employmentsId: this.employments.map(it => it.id) as number[],
				projectId: this.project
			}
		)
		TimesheetService.getPayPeriod(timesheetFilter).then(
			res => {
				this.timesheetPeriods = res.data
			},
			err => processError(err, this)
		)
	}

}

